body {
  margin: 0 !important;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f2224 !important;
  overflow-x: hidden !important;
  color: white !important;
}

.code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
  font-weight: 400 !important;
  letter-spacing: -0.6px;
}

.component-title {
  font-weight: 700;
  font-size: 20px;
}

.bold {
  font-weight: 700;
}
