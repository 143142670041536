.link-container {
  border-radius: 30px;
  overflow: hidden !important;
  background-color: rgba(255, 255, 255, 0.06);
}

.link-container-box {
  padding: 30px 45px;
}

.link-container-side {
  border-left: 10px solid #ff375b;
  height: 100%;
}
