.form-box {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 35px 45px;
  border-radius: 30px;
}

.form-input {
  background-color: transparent !important;
  color: white !important;
  box-shadow: 0 0 12px rgba(255, 55, 91, 0);
}

.form-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cacaca;
  opacity: 1; /* Firefox */
}

.form-input:focus {
  border-color: #ff375b;
  box-shadow: 0 0 12px rgba(255, 55, 91, 0.6);
}

.form-button {
  color: #fff;
  background-color: rgba(255, 55, 91, 1);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 100%;
  box-shadow: 0 0 12px rgba(255, 55, 91, 0.6);
}

.form-button:hover {
  color: #fff !important;
  background-color: rgba(255, 55, 91, 0.7);
}

.error-message {
  color: #ff375b;
  font-weight: 600;
}

.success-message span {
  font-weight: 700;
}

.success-link {
  color: rgba(255, 55, 92, 1);
  text-decoration: none !important;
  transition: all 0.2s ease;
  text-shadow: 0 0 12px rgba(255, 55, 91, 0.6);
  border-bottom: 0px solid rgba(255, 55, 91, 0);
}

.success-link:hover {
  color: rgba(255, 55, 92, 0.7);
  border-bottom: 1px solid rgba(255, 55, 91, 0.7);
  text-decoration: none !important;
}
